import React from 'react';
import { useParams } from 'react-router';
import { KanbanProvider } from 'context/kanban';
import Kanban from './Kanban';
const KanbanView = () => {
  const { id } = useParams();
  //eslint-disable-next-line

  return (
    <>
      <KanbanProvider id={id}>
        <Kanban id={id} />
      </KanbanProvider>
    </>
  );
};

export default KanbanView;
