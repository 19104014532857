import React from 'react';
import { toast } from 'react-toastify';

import { EditableFieldWithPatchRequest, DateInput, CheckboxWithLabel } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';

export function KanbanCalendarItemDateFields({ onUpdateSuccess, updateCalendarItem, initialState }) {
  const [currentStartDate, setCurrentStartDate] = React.useState(initialState.start);
  const [isCompleted, setIsCompleted] = React.useState(initialState.is_completed);

  React.useEffect(() => {
    setIsCompleted(initialState.is_completed);
    setCurrentStartDate(initialState.start);
  }, [initialState]);

  async function handleCompletedToggle(e) {
    const { checked } = e.target;
    const [res, err] = await updateCalendarItem({
      is_completed: checked
    });
    if (!err) {
      onUpdateSuccess(res.data);
      setIsCompleted(checked);
    } else {
      toast.error(err);
    }
  }

  return (
    <>
      <MediaWithIconAndHeading
        icon="calendar-check"
        heading="Start Date"
        content={
          <EditableFieldWithPatchRequest
            label="Start Date"
            initialValue={currentStartDate}
            patchRequest={updateCalendarItem}
            fieldName="start"
            onSuccess={onUpdateSuccess}
            onChange={(userInputDate, setNewValue) => {
              setNewValue(userInputDate);
              setCurrentStartDate(userInputDate);
            }}
            groupedInputProps={{
              showLabelText: false,
              InputComponent: DateInput,
              inputRefPropName: 'ref',
              hasAddon: true
            }}
          />
        }
      />
      <MediaWithIconAndHeading
        icon="check"
        heading="Complete"
        content={
          <CheckboxWithLabel
            label="Complete"
            id="is_completed"
            checked={isCompleted}
            onChange={handleCompletedToggle}
          />
        }
      />
    </>
  );
}
