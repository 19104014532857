// Must keep in sync with the options available in the backend
// in api/planboard/constants/view_options.py:TIME_VIEW_OPTIONS
export const views = new Map([
  ['dayGridWeek', { buttonText: 'Week View' }],
  ['dayGridWorkWeek', { buttonText: 'Work Week View', type: 'dayGridWeek', weekends: false, dayCount: 5 }],
  ['dayGridMonth', { buttonText: 'Month View' }],
  ['timeGridDay', { buttonText: 'Day View' }],
  ['listWeek', { buttonText: 'List View' }],
  ['listYear', { buttonText: 'Year View' }],
  [
    'tagResourceView',
    {
      buttonText: calendarData => `${calendarData?.tagLabels.singularTitle} View`,
      type: 'resourceDayGridDay',
      duration: { days: 1 },
      resources: 'tags',
      calendarProps: {
        editable: false
      }
    }
  ]
]);

export const resourceFormatters = {
  // Refer to the resourceFormatter in the `resources` key of a view.
  // This way we can expand to more resource views later.
  // If anything but tags, we might change the api for the resourceFormatter option
  tags: {
    eventFormatter: event => ({ ...event, resourceId: event.tags[0]?.id || 'unassigned' }),
    resourceFormatter: tags => [
      ...tags.map(tag => ({
        id: tag.id,
        title: tag.label,
        color: tag.color
      })),
      { id: 'unassigned', title: 'Unassigned', color: '#333' }
    ]
  }
};
