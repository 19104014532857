import React, { useContext, useRef, useEffect } from 'react';

import is from 'is_js';

import { KanbanContext } from '../../context/kanban';
import KanbanColumn from './KanbanColumn';

const KanbanContainer = ({ showAllItems }) => {
  const { calendarData } = useContext(KanbanContext);
  const containerRef = useRef(null);

  // Detect device
  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad');
    }
    if (is.mobile()) {
      containerRef.current.classList.add('mobile');
      if (is.safari()) {
        containerRef.current.classList.add('safari');
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome');
      }
    }
  }, []);

  return (
    <div className="kanban-container scrollbar h-100" ref={containerRef}>
      {calendarData &&
        calendarData?.tag_set.map((kanbanColumnItem, index) => {
          return (
            <KanbanColumn
              kanbanColumnItem={kanbanColumnItem}
              key={index}
              index={index}
              sort={true}
              showAllItems={showAllItems}
            />
          );
        })}

      {/* <KanbanModal modal={modal} setModal={setModal} modalContent={modalContent} /> */}
    </div>
  );
};

export default KanbanContainer;
