import React from 'react';
import { Spinner, Card, CardBody } from 'reactstrap';
import FalconCardHeader from 'falcon/components/FalconCardHeader';

import BulkUploadCalendarModal from './BulkUploadCalendarButton';
import ResultsTable from './ResultsTable';

export default function AdminSection() {
  const [results, setResults] = React.useState({ response: null, error: null });
  const [loading, setLoading] = React.useState(false);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Data Sync" />
      <CardBody className="d-flex">
        <div className="w-50">
          <p>Below are the options available to import data from external sources.</p>
          <br />
          <h5>Excel (CSV) Upload</h5>
          <p>Click the button below to see the Calendar Upload wizard.</p>
          {/* <BulkUploadProjectsButton setResults={setResults} setLoading={setLoading} /> */}
          <BulkUploadCalendarModal setResults={setResults} setLoading={setLoading} />
        </div>
        <div className="w-50">
          {loading && <Spinner />}
          {results.response !== null && !loading && <ResultsTable results={results} />}
        </div>
      </CardBody>
    </Card>
  );
}
