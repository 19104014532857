import { KanbanContext } from 'context/kanban';
import ContentWithAsideLayout from 'layouts/ContentWithAsideLayout';
import React, { useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Card, CardBody, Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Backlog from './Backlog';
import KanbanContainer from './KanbanContainer';
import { useToastError } from 'utils';
import { createCalendarItem, putCalendarItemTags, getCalendarCalendarItemsCsv } from 'api/calendar-items';
import { EditKanbanCalendarItemModal } from './EditKanbanCalendarItemModal';
import { CheckboxWithLabel } from 'atoms/form';

const Kanban = ({ id }) => {
  const setError = useToastError();
  const { calendarData, setCalendarItems, editCalendarModalProps } = useContext(KanbanContext);
  const [backlogProjects, setBacklogProjects] = React.useState([]);
  const [toggleBacklogRefresh, setToggleBacklogRefresh] = React.useState(false);
  const [showAllItems, setShowAllItems] = React.useState(false);
  const refreshBacklog = () => {
    setToggleBacklogRefresh(cur => !cur);
  };

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === 'backlog') {
        // sounds and dest aren't the same and source is backlog: ie. Dragging from the Backlog
        const body = {
          start: new Date(),
          end: new Date(),
          project: source.index,
          calendar_metric_value: 0
        };

        const [createCalendarItemResponse, createCalendarItemError] = await createCalendarItem(id, body);
        if (createCalendarItemError) {
          setError(createCalendarItemError);
          return;
        }

        setBacklogProjects(cur => cur.filter(p => p.id !== source.index));
        const [res, err] = await putCalendarItemTags(createCalendarItemResponse.data.id, [destination.droppableId]);
        if (err) {
          setError(err);
          return;
        }
        setCalendarItems(cur => [...cur, res.data]);
      } else if (destination.droppableId !== 'backlog') {
        //source isnt backlog and dest isnt either: ie. Dragging between columns
        const [res, err] = await putCalendarItemTags(parseInt(draggableId), [destination.droppableId]);
        if (err) {
          setError(err);
          return;
        }
        setCalendarItems(cur => {
          const currentItems = [...cur];
          const newItems = currentItems.filter(el => el.id !== res.data.id);
          newItems.push(res.data);
          return newItems;
        });
      } else {
        // dest IS backlog: i.e. Dragging back to Backlog isn't allowed
        setError('To move an item back to the backlog, click on it and delete the item.');
      }
    }
  };

  const downloadClick = async () => {
    if (calendarData && calendarData['id']) {
      await getCalendarCalendarItemsCsv(calendarData['id'], calendarData['name']);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ContentWithAsideLayout
          fullHeight
          ratio={[5, 1]}
          aside={
            <Card className="mt-3">
              <CardBody style={{ height: '600px', overflowY: 'scroll' }}>
                <Backlog
                  toggleRefresh={toggleBacklogRefresh}
                  calendarData={calendarData}
                  backlogProjects={backlogProjects}
                  setBacklogProjects={setBacklogProjects}
                />
              </CardBody>
            </Card>
          }
          banner={
            <Card>
              <CardBody>
                <Row>
                  <Col md="8">
                    <h4>
                      {calendarData?.name} - {calendarData?.tag_label_plural}
                    </h4>
                  </Col>
                  <Col md="1" />
                  <Col md="2" className="text-right pr-0">
                    <UncontrolledTooltip placement="bottom" target="showAll">
                      Checking this will show all (complete and incomplete) items.
                    </UncontrolledTooltip>
                    <CheckboxWithLabel
                      label="Show All Items"
                      id="showAll"
                      checked={showAllItems}
                      onChange={e => setShowAllItems(e.target.checked)}
                    />
                  </Col>
                  <Col md="1" className="text-right">
                    <UncontrolledTooltip placement="bottom" target="download">
                      Download all items as a CSV file.
                    </UncontrolledTooltip>
                    <Button
                      id="download"
                      size="sm"
                      color="falcon-primary"
                      className="mr-2"
                      aria-label="download csv"
                      onClick={downloadClick}
                    >
                      <FontAwesomeIcon icon="arrow-down" />
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          }
        >
          <div className="pt-3 h-100 ">
            <Card style={{ height: '98%' }}>
              <CardBody className="kanban-container scrollbar">
                <KanbanContainer showAllItems={showAllItems} />
              </CardBody>
            </Card>
          </div>
        </ContentWithAsideLayout>
        <EditKanbanCalendarItemModal
          {...editCalendarModalProps}
          setCalendarItems={setCalendarItems}
          refreshBacklog={() => {
            refreshBacklog();
          }}
        />
      </DragDropContext>
    </>
  );
};

export default Kanban;
