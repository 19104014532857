import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import KanbanColumnHeder from './KanbanColumnHeader';
import { KanbanContext } from 'context/kanban';
import { Droppable } from 'react-beautiful-dnd';

import TaskCard from './TaskCard';

const KanbanColumn = ({ kanbanColumnItem, index, sort, showAllItems }) => {
  const { calendarItems } = useContext(KanbanContext);

  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    let filteredTasks = calendarItems?.filter(task => task?.tags[0]?.id === kanbanColumnItem?.id);
    if (filteredTasks) {
      if (sort) filteredTasks.sort((a, b) => new Date(a['start']) - new Date(b['start']));
      if (!showAllItems) filteredTasks = filteredTasks.filter(item => !item.is_completed);
    }

    setFilteredTasks(filteredTasks);
  }, [calendarItems, kanbanColumnItem, showAllItems, sort]);

  useEffect(() => {
    const kanbanContainer = document.getElementById(`container-${index}`);
    kanbanContainer.scrollTop = kanbanContainer.scrollHeight;
  }, [index]);
  return (
    <div className={classNames('kanban-column')}>
      <KanbanColumnHeder kanbanColumnItem={kanbanColumnItem} filteredTasks={filteredTasks} />
      <Droppable droppableId={`${kanbanColumnItem.id}`}>
        {(provided, snapshot) => (
          <>
            <div
              className="kanban-items-container scrollbar h-100"
              id={`container-${index}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {filteredTasks?.map((taskCardItem, taskCardIndex) => {
                const taskCardImage =
                  taskCardItem?.attachments && taskCardItem?.attachments.find(({ type }) => type === 'image');

                return (
                  <TaskCard
                    taskCardImage={taskCardImage}
                    taskCard={taskCardItem}
                    key={taskCardIndex}
                    taskCardIndex={taskCardIndex}
                    taskCardItemId={taskCardItem}
                  />
                );
              })}

              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </div>
  );
};
KanbanColumn.propTypes = {
  kanbanColumnItem: PropTypes.object.isRequired
};
export default KanbanColumn;
