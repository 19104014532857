import { apiHandlerWithError } from 'api';

export async function postExcelProjectImport(file) {
  const formData = new FormData();
  formData.append('file', file);
  return await apiHandlerWithError.post(`importers/excel/projects`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
}

export async function postCsvCalendarImport(file, calendarId) {
  const formData = new FormData();
  formData.append('file', file);
  return await apiHandlerWithError.post(`calendars/${calendarId}/importer/csv`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
}
