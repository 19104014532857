import React from 'react';
import Select from 'react-select';
import { Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';
import { postCsvCalendarImport, getCalendarImportCSVTemplate } from 'api/importers';
import { DropzoneWithErrorHandlingPlaceholder } from 'atoms/form';
import { useCalendarsListContext } from 'context/calendars';
import { useAuthContext } from 'context/auth';

function CalendarSelect({ options, onChange }) {
  return <Select defaultValue={options[0]} options={options} onChange={onChange} />;
}

export default function BulkUploadCalendarModal({ open, toggle, setResults, setLoading }) {
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [calendarOptions, setCalendarOptions] = React.useState();
  const [calendarId, setCalendarId] = React.useState();

  const { calendars } = useCalendarsListContext();

  const {
    authState: { projectLabels }
  } = useAuthContext();

  React.useEffect(() => {
    if (calendars.length > 0) {
      const options = calendars.map(({ id, name }) => ({
        value: id,
        label: name
      }));

      setCalendarOptions(options);
      setCalendarId(options[0].value);
    }
  }, [calendars]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (!file) {
      setError('Upload a file that matches the template provided.');
      return;
    }
    const [response, error] = await postCsvCalendarImport(file.file, calendarId);
    setResults({
      response,
      error
    });
    setLoading(false);
    toggle();
    setFile(null);
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          Bulk Upload {projectLabels.pluralTitle}
        </ModalHeader>
        <ModalBody>
          Select The Calendar to Import into:
          <CalendarSelect options={calendarOptions} onChange={e => setCalendarId(e.value)} />
          <br />
          <DropzoneWithErrorHandlingPlaceholder
            preview
            previewHasImage={false}
            reactDropzoneProps={{
              onDropRejected: fileRejections => {
                setError(`Files not accepted: ${fileRejections.map(({ path }) => path).join(', ')}`);
                setFile(null);
              },
              maxFiles: 1,
              accept:
                '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }}
            onChange={files => {
              setError(null);
              setFile(files[0]);
            }}
            files={file ? [file] : null}
            error={error}
          />
          <ButtonIcon
            onClick={() => getCalendarImportCSVTemplate(calendarId)}
            color="falcon-default"
            icon="file-download"
            size="sm"
            className="mr-3 mt-3"
          >
            Download Template
          </ButtonIcon>
        </ModalBody>
        <ModalFooter>
          <ButtonIcon color="falcon-primary" type="submit" icon="check" size="sm" className="m-2">
            Upload
          </ButtonIcon>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
