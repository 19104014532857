import React, { useState } from 'react';
import ButtonIcon from 'falcon/components/ButtonIcon';

import { useAuthContext } from 'context/auth';

import BulkUploadCalendarModal from './BulkUploadCalendarModal';

export default function UpdateCalendarButton({ setResults, setLoading }) {
  const [modalState, setModalState] = useState({ open: false });
  const toggleModal = () => setModalState(cur => ({ ...cur, open: !cur.open }));

  const {
    authState: { projectLabels }
  } = useAuthContext();

  return (
    <>
      <ButtonIcon
        icon="sync-alt"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="m-2"
        onClick={toggleModal}
      >
        Bulk Import {projectLabels.singularTitle} dates into a Calendar
      </ButtonIcon>
      <BulkUploadCalendarModal
        open={modalState.open}
        toggle={toggleModal}
        setResults={setResults}
        setLoading={setLoading}
      />
    </>
  );
}
