import React from 'react';
import { Modal, ModalHeader, ModalBody, NavLink, ModalFooter, Badge, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import { patchCalendarItem } from 'api/calendar-items';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { useAuthContext } from 'context/auth';
import { DatedNoteTextArea, EditableDecimalField } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';
import { useProjectFields } from 'atoms/projects';
import { updateCalendarItems } from 'actions/calendars';

import { KanbanCalendarItemDateFields } from './KanbanCalendarItemDateFields';
import DeleteCalendarItemButton from 'atoms/calendars/DeleteCalendarItemButton';

export function EditKanbanCalendarItemModal({
  calendarData,
  open,
  toggle,
  setCalendarItems,
  initialState,
  id,
  transformCalendarItem = item => item,
  refreshBacklog = () => {}
}) {
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    if (initialState) {
      setTags(initialState.tags);
    }
  }, [initialState]);

  const updateCalendarItem = async body => {
    return await patchCalendarItem(id, body);
  };

  function deleteSuccessCallback() {
    updateCalendarItems(transformCalendarItem, setCalendarItems, refreshBacklog, {
      removedItemId: id
    });
    toggle();
  }

  function onUpdateSuccess(updatedCalendarItem) {
    toast.success('Calendar Item updated.');
    updateCalendarItems(transformCalendarItem, setCalendarItems, refreshBacklog, {
      removedItemId: id,
      addedItem: updatedCalendarItem
    });
  }

  const project = initialState?.project;
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();
  const projectFields = useProjectFields();
  return (
    <>
      <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          <span className="mr-3">{project && project['name']}</span>
          {tags.map(tag => (
            <Badge key={tag.id} style={{ backgroundColor: tag.color }} className="mx-1" pill>
              {tag.label}
            </Badge>
          ))}
        </ModalHeader>
        <ModalBody>
          {projectFields.map(({ key, label }) => (
            <p className="mb-1" key={`project-${key}`}>
              <strong>{label}: </strong>
              {project && project[key]}
            </p>
          ))}
          <KanbanCalendarItemDateFields
            onUpdateSuccess={onUpdateSuccess}
            updateCalendarItem={updateCalendarItem}
            initialState={initialState}
          />
          {/* TODO: Add an icon to the calendar object for this. */}
          <MediaWithIconAndHeading icon="dollar-sign" heading={''}>
            <Row>
              <Col>
                <Label className="mr-2">Estimated {calendarData?.metric_label}: </Label>
                <EditableDecimalField
                  label="Estimate value"
                  initialValue={initialState.calendar_metric_estimate}
                  patchRequest={updateCalendarItem}
                  fieldName="calendar_metric_estimate"
                  onSuccess={onUpdateSuccess}
                />
              </Col>
              <Col>
                <Label className="mr-2">Actual {calendarData?.metric_label}: </Label>
                <EditableDecimalField
                  label="Actual value"
                  initialValue={initialState.calendar_metric_value}
                  patchRequest={updateCalendarItem}
                  fieldName="calendar_metric_value"
                  onSuccess={onUpdateSuccess}
                />
              </Col>
            </Row>
          </MediaWithIconAndHeading>
          <MediaWithIconAndHeading icon="pencil-alt" heading="Note">
            <DatedNoteTextArea
              label="Note"
              initialValue={initialState.note}
              patchRequest={updateCalendarItem}
              fieldName="note"
              onSuccess={onUpdateSuccess}
            />
          </MediaWithIconAndHeading>
        </ModalBody>
        <ModalFooter className="bg-light px-card border-top-0">
          <ButtonIcon
            icon="link"
            Component={NavLink}
            className="py-1 px-2 m-2 btn btn-falcon-default btn-sm"
            href={`/projects/${project?.id}`}
            target="_blank"
          >
            {singularTitle} Details
          </ButtonIcon>
          <DeleteCalendarItemButton
            icon="trash-alt"
            size="sm"
            className="m-2 btn-falcon-danger"
            id={id}
            onSuccess={deleteSuccessCallback}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
