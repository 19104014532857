import React from 'react';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'falcon/components/Flex';
import { currencyFormatter } from 'falcon/helpers/utils';
import { SelectCalendarViewDropdown } from 'atoms/calendars';
import { getCalendarCalendarItemsCsv } from 'api/calendar-items';

export default function CalendarHeader({
  title,
  calendarData,
  currentViewTotalValue,
  calendarApi,
  toggleCreateCalendarItemModal,
  selectCalendarViewDropdownProps
}) {
  const downloadClick = async () => {
    if (calendarData && calendarData['id']) {
      await getCalendarCalendarItemsCsv(calendarData['id'], calendarData['name']);
    }
  };
  return (
    <Card>
      <CardBody>
        <Row noGutters className="align-items-center">
          <Col xs="auto" className="d-flex justify-content-end order-md-1">
            <UncontrolledTooltip placement="bottom" target="previous">
              Previous
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target="next">
              Next
            </UncontrolledTooltip>
            <Button
              onClick={() => {
                // Needs to be an inline func for some reason, just calendarApi.prev won't work.
                if (calendarApi) {
                  calendarApi.prev();
                }
              }}
              color="link"
              className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1 ml-md-2"
              id="previous"
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            <Button
              onClick={() => {
                if (calendarApi) {
                  calendarApi.next();
                }
              }}
              color="link"
              className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
              id="next"
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </Col>
          <Col xs="auto" md="auto" className="order-md-2">
            <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">{title}</h4>
            Total {calendarData?.metric_label}: {currencyFormatter(currentViewTotalValue)}
          </Col>
          <Col xs md="auto" tag={Flex} justify="end" className="order-md-3">
            <Button
              size="sm"
              color="falcon-primary"
              className="mr-2"
              onClick={() => {
                calendarApi.today();
              }}
            >
              Today
            </Button>
          </Col>
          <Col md="auto" className="d-md-none">
            <hr />
          </Col>
          <Col xs="auto" className="d-flex order-md-0">
            <Button color="primary" size="sm" className="pt-1" onClick={toggleCreateCalendarItemModal}>
              <FontAwesomeIcon icon="plus" className="mr-1" /> Schedule Item ({calendarData?.name})
            </Button>
          </Col>
          <Col className="d-flex justify-content-end order-md-2" style={{ zIndex: 1016 }}>
            {selectCalendarViewDropdownProps.currentFilter && (
              <SelectCalendarViewDropdown {...selectCalendarViewDropdownProps} />
            )}
          </Col>
          <Col xs md="auto" className="order-md-4" justify="end">
            <UncontrolledTooltip placement="bottom" target="download">
              Download all the events on this calendar as a CSV file.
            </UncontrolledTooltip>
            <Button
              id="download"
              size="sm"
              color="falcon-primary"
              className="mr-2"
              aria-label="Download all the events on this calendar as a CSV file"
              onClick={downloadClick}
            >
              <FontAwesomeIcon icon="arrow-down" />
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
