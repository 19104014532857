import { patchCalendarItem } from 'api/calendar-items';
import { addDays } from 'date-fns';

export default async function moveEvent(e, setCalendarItems, setError, transformCalendarItem) {
  /* We need a consistent interpretation of start and end dates. 
     One-day events have only start-date and a null end date.
     Multi-day events have start-date and an end-date.
     Because of FullCalendar, the end-date is a day after the actual date shown on the Calendar. (That is
      because FullCalendar uses 12AM of the next day as the end-date)
  */

  var end_date = null;

  if (e.event.end > addDays(e.event.start, 1)) {
    end_date = e.event.end;
  }

  const [res, err] = await patchCalendarItem(e.event.id, {
    start: e.event.start,
    end: end_date
  });
  if (err) {
    setError(err);
    e.revert();
    return;
  }
  // filter the old element from calendar items and add the new one in.
  setCalendarItems(cur => [...cur.filter(el => el.id !== Number(e.event.id)), transformCalendarItem(res.data)]);
}
