// note: this component will not handle "required" in the expected way if passed
// if you set required, the form will validate properly,
// but the error message will be hidden because `react-dropzone` sets the input to hidden
// better that you handle required in your `handleSubmit` and pass down the error to this component

import React from 'react';
import { Media } from 'reactstrap';

import cloudUpload from 'falcon/components/img/cloud-upload.svg';
import FalconDropzone from 'falcon/components/FalconDropzone';

export default function DropzoneWithErrorHandlingPlaceholder({ error, files, ...falconDropzoneProps }) {
  return (
    <FalconDropzone
      placeholder={
        <>
          <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
            <img src={cloudUpload} alt="" width={25} className="mr-2" />
            <Media>
              <p className="fs-0 mb-0 text-700">Upload file</p>
            </Media>
          </Media>
          {!files && (
            <p className={`mb-0 w-75 mx-auto text-${error ? 'danger' : '500'}`}>
              {error || 'Upload a file that matches the structure of the template provided.'}
            </p>
          )}
        </>
      }
      files={files}
      {...falconDropzoneProps}
    />
  );
}
