import React, { useEffect, useRef } from 'react';
// will throw error if Draggable is imported without FullCalendar
// eslint-disable-next-line
import FullCalendar from '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
import { Card, CardHeader } from 'reactstrap';

// Update this if the data spec for a calendar event changes.
// Must be able to be used in the `eventContent` function in `ViewCalendar/index.js`.
function createEventData(id, project, calendarData) {
  return {
    id,
    allDay: true,
    extendedProps: {
      calendar: calendarData.id,
      calendar_metric_value: 0,
      tags: [],
      project
    }
  };
}

const BacklogItem = ({ children, id, project, calendarData }) => {
  const draggableElement = useRef();

  useEffect(() => {
    const draggable = new Draggable(draggableElement.current, {
      eventData: createEventData(id, project, calendarData)
    });
    // destroy draggable element on unmount, otherwise multiple will exist
    return () => draggable.destroy();
  }, [project, id, calendarData]);

  return (
    <Card className="fc-daygrid-event my-2 text-wrap">
      <CardHeader className={`bg-soft-purple-theme py-1 px-2`}>
        <div ref={draggableElement}>{children}</div>
      </CardHeader>
    </Card>
  );
};

export default BacklogItem;
