import { useAuthContext } from 'context/auth';

export default function useProjectFields() {
  const { authState } = useAuthContext();

  return [
    {
      key: 'name',
      label: `${authState.projectLabels.singularTitle} Name`,
      required: true
    },
    {
      key: 'external_id',
      label: 'External Id'
    },
    {
      key: 'address',
      label: `${authState.projectLabels.singularTitle} Address`
    },
    {
      key: 'customer_name',
      label: 'Customer Name'
    },
    {
      key: 'customer_email',
      label: 'Customer Email'
    },
    {
      key: 'customer_phone',
      label: 'Customer Phone'
    }
  ];
}
