import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from 'atoms/misc/Table';

const sort = {
  sortCaret: function SortCaret(order) {
    return <FontAwesomeIcon className="ml-3" icon={!order ? 'sort' : order === 'asc' ? 'caret-up' : 'caret-down'} />;
  }
};

const defaultColumns = [
  {
    dataField: 'id',
    text: 'PlanBoard ID',
    sort: true,
    align: 'right'
  },
  {
    dataField: 'external_id',
    text: 'External ID',
    sort: true,
    align: 'right'
  }
];

export default function OrdersTable({ orders }) {
  const jsonColumns = React.useMemo(
    // Creates a column for the table for each unique key in the extra_fields_json for all orders.
    () =>
      Array.from(
        new Set(
          orders
            .reduce((acc, order) => {
              if (!order.extra_fields_json) return acc;
              acc.push(Object.keys(JSON.parse(order.extra_fields_json)));
              return acc;
            }, [])
            .flat()
        )
      ).map(name => ({
        dataField: 'extra_fields_json',
        text: name,
        sort: false,
        align: 'right',
        formatter: extra_fields_json => extra_fields_json && JSON.parse(extra_fields_json)[name]
      })),
    [orders]
  );

  return (
    <Table
      sort={sort}
      columns={defaultColumns.concat(jsonColumns)}
      defaultSorted={[
        {
          dataField: 'id',
          order: 'desc'
        }
      ]}
      data={orders}
      keyField="id"
      classes="table-dashboard table-striped fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
      rowClasses="btn-reveal-trigger"
      headerClasses="bg-400 text-900"
    />
  );
}
