import React from 'react';

export default function useEditCalendarItemModal(getCalendarDataFromEvent) {
  const [editCalendarItemModalState, setEditCalendarItemModalState] = React.useState({
    open: false,
    initialState: {
      start: new Date(),
      end: null,
      calendar_metric_value: 0,
      calendar_metric_estimate: 0,
      note: '',
      tags: []
    }
  });

  const toggleEditCalendarItemModal = () => {
    setEditCalendarItemModalState(cur => ({
      ...cur,
      open: !cur.open
    }));
  };

  const handleCalendarEventClick = event => {
    const { start, end, extendedProps, id } = event.event;
    setEditCalendarItemModalState(cur => ({
      ...cur,
      id,
      open: true,
      initialState: {
        start,
        end,
        ...extendedProps
      },
      calendarData: getCalendarDataFromEvent(event.event)
    }));
  };

  return {
    handleCalendarEventClick,
    editCalendarModalProps: {
      id: editCalendarItemModalState.id,
      open: editCalendarItemModalState.open,
      toggle: toggleEditCalendarItemModal,
      initialState: editCalendarItemModalState.initialState,
      calendarData: editCalendarItemModalState.calendarData
    }
  };
}
