import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from 'falcon/components/FalconCardHeader';

import { getProjectDetails, patchProject } from 'api/projects';
import { useToastError } from 'utils';
import { EditableFieldWithPatchRequest } from 'atoms/form';
import { useAuthContext } from 'context/auth';
import { DeleteProjectButton, ProjectActivityLog } from 'atoms/projects';
import { PermissionRequired } from 'atoms/users';
import { ProjectTimelineCalendar } from 'views/calendars';

import OrdersTable from './OrdersTable';
import ExtraFieldsList from './ExtraFieldsList';

const FAILED_UPDATE_ERROR_MESSAGE = 'Failed to update project';

export default function ProjectDetailsPage() {
  const setError = useToastError();
  const { id } = useParams();
  const [project, setProject] = useState();
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();

  React.useEffect(() => {
    async function getProject() {
      const [res, err] = await getProjectDetails(id);
      if (err) {
        setError(`Failed to get ${singularTitle}.`);
        setProject();
        return;
      }
      setProject(res.data);
    }
    getProject();
  }, [id, setError, singularTitle]);

  const patchProjectRequest = async body => await patchProject(id, body);

  const history = useHistory();

  if (!project) return <></>;

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title={`${singularTitle} Details`}>
          <PermissionRequired permission="delete_project">
            <DeleteProjectButton
              icon="trash-alt"
              size="sm"
              className="m-2 btn-falcon-danger"
              id={id}
              onSuccess={() => {
                history.push('/');
              }}
            />
          </PermissionRequired>
        </FalconCardHeader>
        <CardBody>
          <Row>
            <Col sm="6">
              <EditableFieldWithPatchRequest
                initialValue={project.external_id || ''}
                label="External ID"
                patchRequest={patchProjectRequest}
                fieldName="external_id"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
              <EditableFieldWithPatchRequest
                initialValue={project.name}
                label={`${singularTitle} Name`}
                patchRequest={patchProjectRequest}
                fieldName="name"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
              <EditableFieldWithPatchRequest
                initialValue={project.address || ''}
                label={`${singularTitle} Address`}
                patchRequest={patchProjectRequest}
                fieldName="address"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
            </Col>
            <Col sm="6">
              <EditableFieldWithPatchRequest
                initialValue={project.customer_name || ''}
                label="Customer Name"
                patchRequest={patchProjectRequest}
                fieldName="customer_name"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
              <EditableFieldWithPatchRequest
                initialValue={project.customer_email || ''}
                label="Customer Email"
                patchRequest={patchProjectRequest}
                fieldName="customer_email"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
              <EditableFieldWithPatchRequest
                initialValue={project.customer_phone || ''}
                label="Customer Phone"
                patchRequest={patchProjectRequest}
                fieldName="customer_phone"
                errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
                onSuccess={setProject}
              />
            </Col>
          </Row>          
          {project.extra_fields_json && <ExtraFieldsList project={project} />}
          {project.order_set.length > 0 && <OrdersTable orders={project.order_set} />}
        </CardBody>
      </Card>
      <Card className="mb-3">
        <FalconCardHeader title="Planned Items" />
        <div style={{ minHeight: '250px' }}>
          <ProjectTimelineCalendar projectId={id} />
        </div>
      </Card>
      {
        <PermissionRequired permission="view_projectactivitylog">
          <ProjectActivityLog projectId={id} />
        </PermissionRequired>
      }
    </>
  );
}
