import React from 'react';

import { useAuthContext } from 'context/auth';

export default function ResultsTable({ results }) {
  const {
    authState: { projectLabels }
  } = useAuthContext();
  const { successDetails, successErrorsArray } = React.useMemo(() => {
    if (!results.response || results.error) return {};
    const { errors, ...successDetails } = results.response.data;
    return {
      successDetails,
      successErrorsArray: errors
    };
  }, [results]);

  const resultKeyLabelMap = {
    new_projects: `New ${projectLabels.pluralTitle}`,
    updated_projects: `Updated ${projectLabels.pluralTitle}`,
    new_orders: 'New Orders',
    updated_orders: 'Updated Orders'
  };
  return (
    <div className="mw-50">
      <h4 className="text-center">Results</h4>
      <h5>Status</h5>
      <p>{results.response.statusText}</p>
      <h5>Details</h5>
      {results.error ? (
        results.error
      ) : (
        <>
          <ul>
            {Object.entries(successDetails).map(([key, val]) => (
              <li key={key}>
                <strong>{resultKeyLabelMap[key]}: </strong>
                {val}
              </li>
            ))}
          </ul>
          <h5>Errors</h5>
          <ol>{successErrorsArray?.map((el, i) => <li key={i}>{el}</li>)}</ol>
        </>
      )}
    </div>
  );
}
