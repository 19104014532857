import React from 'react';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { toast } from 'react-toastify';

import { useToastError } from 'utils';
import { deleteCalendarItem } from 'api/calendar-items';

export default function DeleteCalendarItemButton({ id, onSuccess = () => {}, ...buttonIconProps }) {
  const setError = useToastError();

  async function handleDelete() {
    if (!window.confirm('Delete item?')) return;

    const [, responseError] = await deleteCalendarItem(id);
    if (responseError) {
      setError('An error occurred and deleting the item failed.');
      return;
    }
    toast.success('Item deleted');
    onSuccess(id);
  }

  return (
    <ButtonIcon onClick={handleDelete} {...buttonIconProps}>
      Delete
    </ButtonIcon>
  );
}
