import React from 'react';
import { Badge } from 'reactstrap';

const TagBadge = ({ color, text }) => {
  return (
    <Badge pill style={{ backgroundColor: color, margin: '0 0.5px' }}>
      { Text = text }
      &nbsp;
    </Badge>
  );
};

export default TagBadge;
