import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

import useProjectFields from './useProjectFields';

/**
 *
 * @param {state}: state that contains keys for all elements of fields
 * @param {inputProps}: will be spread into EVERY input
 */
export default function CreateProjectFormFields({ validate = true, state, setState, ...inputProps }) {
  function handleChange(e, fieldName) {
    setState({
      ...state,
      [fieldName]: e.target.value
    });
  }

  const fields = useProjectFields();

  return (
    <>
      {fields.map(({ key, label, required }) => {
        const id = `project-${key}`;
        return (
          <FormGroup key={key}>
            <Label for={id}>
              {label}
              {required && ' (Required)'}
            </Label>
            <Input
              required={validate && Boolean(required)}
              className="mr-3"
              value={state[key]}
              onChange={e => {
                handleChange(e, key);
              }}
              id={id}
              {...inputProps}
            />
          </FormGroup>
        );
      })}
    </>
  );
}
