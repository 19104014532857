import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
// will throw error if Draggable is imported without FullCalendar
// eslint-disable-next-line

import { Card, CardHeader } from 'reactstrap';

// Update this if the data spec for a calendar event changes.
// Must be able to be used in the `eventContent` function in `ViewCalendar/index.js`.

const BacklogItem = ({ children, id, project, calendarData }) => {
  return (
    <Draggable draggableId={`draggableId${project.id}`} index={project.id}>
      {provided => (
        <div
          className="kanban-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
        >
          <Card className="fc-daygrid-event my-2 text-wrap">
            <CardHeader className={`bg-soft-purple-theme py-1 px-2`}>
              <div>{children}</div>
            </CardHeader>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default BacklogItem;
