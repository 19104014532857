import React from 'react';

const KanbanColumnHeder = ({ kanbanColumnItem, filteredTasks }) => {
  return (
    <div className="kanban-column-header">
      <p className="mb-0">
        <strong>{kanbanColumnItem.label} </strong>
        <span className="text-500">({filteredTasks?.length})</span>
        <br />
        <span className="small">
          Total Actual:{' '}
          {filteredTasks?.reduce(
            (acc, task) => acc + parseFloat(task.calendar_metric_value ? task.calendar_metric_value : 0),
            0
          )}
          <br />
          Total Estimate:{' '}
          {filteredTasks?.reduce(
            (acc, task) => acc + parseFloat(task.calendar_metric_estimate ? task.calendar_metric_estimate : 0),
            0
          )}
        </span>
      </p>
    </div>
  );
};

export default KanbanColumnHeder;
