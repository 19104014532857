import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import FalconCardHeader from 'falcon/components/FalconCardHeader';
import { useAuthContext } from 'context/auth';
import { patchCompany } from 'api/companies';
import { EditableFieldWithPatchRequest } from 'atoms/form';

const FAILED_UPDATE_ERROR_MESSAGE = 'Failed to update company.';

export default function CompanySettingsSection() {
  const {
    authState: { user },
    authDispatch
  } = useAuthContext();

  const patchCompanyRequest = async body => await patchCompany(user.userdetails.company.id, body);

  function onSuccess(updatedCompany) {
    authDispatch({
      type: 'UPDATE_COMPANY_DETAILS',
      payload: updatedCompany
    });
    toast.success('Updated successfully');
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Company Settings" light={false} />
      <CardBody className="p-3">
        <EditableFieldWithPatchRequest
          initialValue={user.userdetails.company.name || ''}
          label="Name"
          patchRequest={patchCompanyRequest}
          fieldName="name"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={user.userdetails.company.logo_url || ''}
          label="Company Logo URL"
          patchRequest={patchCompanyRequest}
          fieldName="logo_url"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={user.userdetails.company.project_label_singular || ''}
          label="Project Label (singular)"
          patchRequest={patchCompanyRequest}
          fieldName="project_label_singular"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={user.userdetails.company.project_label_plural || ''}
          label="Project Label (plural)"
          patchRequest={patchCompanyRequest}
          fieldName="project_label_plural"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
      </CardBody>
    </Card>
  );
}
