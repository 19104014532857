import React from 'react';
import { addDays, subDays } from 'date-fns';
import { toast } from 'react-toastify';

import { EditableFieldWithPatchRequest, DateInput, CheckboxWithLabel } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';

const checkIfMultiDay = initialState => Boolean(initialState.end) && initialState.end > addDays(initialState.start, 1);

export default function CalendarItemDateFields({ onUpdateSuccess, updateCalendarItem, initialState }) {
  const [currentStartDate, setCurrentStartDate] = React.useState(initialState.start);
  const [isMultiDay, setIsMultiDay] = React.useState(checkIfMultiDay(initialState));

  React.useEffect(() => {
    setIsMultiDay(checkIfMultiDay(initialState));
    setCurrentStartDate(initialState.start);
  }, [initialState]);

  async function handleMultiDayToggle(e) {
    const { checked } = e.target;
    const [res, err] = await updateCalendarItem({
      end: checked ? addDays(currentStartDate, 2) : null
    });
    if (!err) {
      onUpdateSuccess(res.data);
      setIsMultiDay(checked);
    } else {
      toast.error(err);
    }
  }

  return (
    <>
      <MediaWithIconAndHeading
        icon="calendar-check"
        heading="Start Date"
        content={
          <EditableFieldWithPatchRequest
            label="Start Date"
            initialValue={initialState.start}
            patchRequest={updateCalendarItem}
            fieldName="start"
            onSuccess={onUpdateSuccess}
            onChange={(userInputDate, setNewValue) => {
              setNewValue(userInputDate);
              setCurrentStartDate(userInputDate);
            }}
            groupedInputProps={{
              showLabelText: false,
              InputComponent: DateInput,
              inputRefPropName: 'ref',
              hasAddon: true
            }}
          />
        }
      />
      <MediaWithIconAndHeading
        icon="check"
        heading="Multi-day event"
        content={
          <CheckboxWithLabel
            label="Multi-day event"
            id="multi-day-event"
            checked={isMultiDay}
            onChange={handleMultiDayToggle}
          />
        }
      />
      {isMultiDay && (
        <MediaWithIconAndHeading
          icon="calendar-check"
          heading="End Date"
          content={
            <EditableFieldWithPatchRequest
              label="End Date"
              initialValue={initialState.end || addDays(currentStartDate, 2)}
              patchRequest={updateCalendarItem}
              fieldName="end"
              onSuccess={onUpdateSuccess}
              onChange={(userInputDate, setNewValue) => {
                setNewValue(userInputDate);
              }}
              readOnlyFormatter={date => date && subDays(date, 1)}
              onStartEditing={setNewValue => {
                setNewValue(cur => cur && subDays(cur, 1));
              }}
              parseUserInput={userInputDate => userInputDate && addDays(userInputDate, 1)}
              groupedInputProps={{
                showLabelText: false,
                InputComponent: DateInput,
                inputRefPropName: 'ref',
                minDate: currentStartDate && addDays(currentStartDate, 1),
                hasAddon: true
              }}
            />
          }
        />
      )}
    </>
  );
}
