import { toast } from 'react-toastify';
import { downloadBlob } from 'falcon/helpers/utils';
import { apiHandlerWithError } from 'api/apiHandler';

export default async function getCalendarCalendarItemsCsv(calendarId, calendarName) {
  const [response, responseError] = await apiHandlerWithError(`calendars/${calendarId}/exporter/csv`, {
    responseType: 'blob'
  });
  if (responseError) {
    toast.error('Failed to download template');
  } else {
    let newName = calendarName.replace(/\W/g,'-'); // /\W/ is equivalent to [^A-Za-z0-9_]. Matches any character that is not a word character from the basic Latin alphabet.
    downloadBlob(response.data, `planboard-calendar-${newName}.csv`);
  }
}