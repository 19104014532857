import { camelToTitle } from 'utils/formatters';

import { views } from './views';

function getProperties(fieldOrder) {
  const properties = {
    name: {
      title: 'Name',
      type: 'string'
    },
    metric_label: {
      title: 'Metric Label',
      type: 'string',
      default: 'dollars'
    },
    default_initial_view: {
      title: 'Default Initial View',
      type: 'string',
      enum: Array.from(views.keys()),
      enumNames: Array.from(views.entries()).map(([key, val]) => {
        if (typeof val.buttonText === 'string') return val.buttonText;
        return camelToTitle(key);
      }),
      default: 'dayGridWeek'
    },
    calendar_event_box_color: {
      title: 'Color',
      type: 'string',
      default: '#e6effc'
    },
    tag_label_singular: {
      title: 'Tag Label (singular)',
      type: 'string',
      default: 'tag'
    },
    tag_label_plural: {
      title: 'Tag Label (plural)',
      type: 'string',
      default: 'tags'
    },
    has_tags_view: {
      title: 'Enable Tags View',
      type: 'boolean',
      default: false
    }
  };
  return fieldOrder.reduce((acc, fieldName) => {
    acc[fieldName] = properties[fieldName];
    return acc;
  }, {});
}
const uiPropertySettings = {
  calendar_event_box_color: {
    'ui:widget': 'color'
  }
};

/**
 *
 * @param {array} fieldOrder : list of fields to display in the correct order
 * @returns schema and uiSchema to feed into RJSF, which includes only the requested fields
 */
export default function useCalendarSchemas(fieldOrder) {
  const schema = {
    type: 'object',
    required: [
      'name',
      'metric_label',
      'default_initial_view',
      'calendar_event_box_color',
      'tag_label_singular',
      'tag_label_plural'
    ].filter(fieldName => fieldOrder.includes(fieldName)),
    properties: getProperties(fieldOrder)
  };
  const uiSchema = {
    'ui:submitButtonOptions': { norender: true },
    'ui:order': fieldOrder,
    ...fieldOrder.reduce((acc, fieldName) => {
      acc[fieldName] = uiPropertySettings[fieldName];
      return acc;
    }, {})
  };
  return { schema, uiSchema };
}
