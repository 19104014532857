import React from 'react';

import { useEditCalendarItemModal } from 'atoms/calendars';
import { resourceFormatters, views } from 'atoms/calendars/views';
import { useCalendar } from 'context/calendars';
import { useToastError } from 'utils';
import { getCalendarCalendarItems } from 'api/calendar-items';

export const KanbanContext = React.createContext();

export function KanbanProvider({ id, children }) {
  const [calendarApi, setCalendarApi] = React.useState();
  const { calendarData } = useCalendar(id, calendarApi, views, resourceFormatters);
  const [calendarItems, setCalendarItems] = React.useState(null);
  const setError = useToastError();

  React.useEffect(() => {
    async function loadCalendarItems() {
      await getCalendarCalendarItems(id, setCalendarItems, setError);
    }
    loadCalendarItems();
  }, [id, setError]);

  const { editCalendarModalProps, handleCalendarEventClick } = useEditCalendarItemModal(() => calendarData);

  const getItemStyle = isDragging => ({
    // change background colour if dragging
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-3deg)' : '',
    transition: 'all 0.3s ease-out'

    // styles we need to apply on draggables
  });

  const value = {
    calendarApi,
    setCalendarApi,
    calendarData,
    calendarItems,
    setCalendarItems,
    getItemStyle,
    editCalendarModalProps,
    handleCalendarEventClick
  };

  return <KanbanContext.Provider value={value}>{children}</KanbanContext.Provider>;
}
