import React from 'react';
import {CircleStackIcon} from 'atoms/misc';
import { TagBadge } from 'atoms/calendars';

const CalendarEventBox = ({ project, id, titleLinksToProject, calendarItem }) => {
  var cardLabel = project.name;
  if (project.external_id)  {    
    cardLabel = project.external_id + ": " + cardLabel;
  }  
  return ( 
    <div className="text-wrap cursor-pointer" id={id}>
      <div className="d-flex">
      {calendarItem?.is_completed ? <CircleStackIcon iconName="check" /> : null}
        {calendarItem?.tags.map(tag => (
          <React.Fragment key={tag.id}>
            <TagBadge color={tag.color} text={tag.display_label} />
          </React.Fragment>
        ))}
      </div>
      <strong>
        {titleLinksToProject ? (
          <a href={`/projects/${project.id}`}>
            {cardLabel}            
          </a>
        ) : (
          <>
            {cardLabel}
          </>
        )}
      </strong>
    </div>
  );
};

export default CalendarEventBox;
