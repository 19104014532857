import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getBacklog } from 'api/calendars';
import { useAuthContext } from 'context/auth';
import { StyledTooltip } from 'atoms/misc';
import { CreateProjectButton } from 'atoms/projects';

import BacklogItem from './BacklogItem';
import { Droppable } from 'react-beautiful-dnd';

const Backlog = ({ calendarData, backlogProjects, setBacklogProjects, toggleRefresh }) => {
  React.useEffect(() => {
    if (!calendarData?.id) return;
    async function getBacklogProjects() {
      const [res, err] = await getBacklog(calendarData.id);
      if (err) {
        setBacklogProjects([]);
      } else {
        setBacklogProjects(res.data);
      }
    }
    getBacklogProjects();
  }, [calendarData, setBacklogProjects, toggleRefresh]);

  const {
    authState: { projectLabels }
  } = useAuthContext();

  return (
    <Droppable droppableId="backlog">
      {provided => (
        <div
          className="kanban-items-container h-100"
          id={`container-backlog`}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="text-center">
            <div className="d-flex">
              <h6 className="mb-3 mr-3">"{calendarData?.name}" Backlog</h6>
              <FontAwesomeIcon id="backlog-info-tooltip" size="sm" className="cursor-pointer" icon="info-circle" />
              <StyledTooltip target="backlog-info-tooltip" autohide={false}>
                <p>{projectLabels.pluralTitle} which are ready to be scheduled on this calendar.</p>
              </StyledTooltip>
            </div>
            {backlogProjects.map(project => (
              <BacklogItem key={project.id} project={project} calendarData={calendarData}>
                <div className="d-flex justify-content-between">
                  {project.id}-{project.name}
                </div>
              </BacklogItem>
            ))}
            {provided.placeholder}
            <CreateProjectButton
              onSuccess={res => {
                setBacklogProjects(cur => [...cur, res.data]);
              }}
              buttonIconProps={{
                size: 'sm',
                className: 'mt-3',
                color: 'falcon-default'
              }}
            />
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default Backlog;
