import React from 'react';
import { Nav } from 'reactstrap';
// import { FalconContext } from 'falcon';

import ProfileDropdown from './ProfileDropdown';

const TopNavRightSideNavItem = () => {
  // const { isTopNav, isCombo } = useContext(FalconContext);
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
