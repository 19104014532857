import { toast } from 'react-toastify';
import { downloadBlob } from 'falcon/helpers/utils';
import { apiHandlerWithError } from 'api/apiHandler';

export async function getExcelProjectImportTemplate() {
  const [response, responseError] = await apiHandlerWithError('importers/excel/projects', {
    responseType: 'blob'
  });
  if (responseError) {
    toast.error('Failed to download template');
  } else {
    downloadBlob(response.data, `planboard-bulk-upload-projects-template.csv`);
  }
}

export async function getCalendarImportCSVTemplate(calendarId) {
  const [response, responseError] = await apiHandlerWithError(`calendars/${calendarId}/importer/csv`, {
    responseType: 'blob'
  });
  if (responseError) {
    toast.error('Failed to download Calendar template');
  } else {
    downloadBlob(response.data, `planboard-bulk-upload-calendar-template.csv`);
  }
}
