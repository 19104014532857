import React from 'react';

import EditableFieldWithPatchRequest from './EditableFieldWithPatchRequest';

export default function EditableDecimalField({
  groupedInputProps = {
    showLabelText: false
  },
  groupedInputClassName = '',
  ...editableFieldWithPatchRequestProps
}) {
  const readOnlyFormatter = val =>
    Number(val)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const handleChange = (e, setNewValue) => {
    setNewValue(e.target.value.replace(',', ''));
  };

  return (
    <EditableFieldWithPatchRequest
      groupedInputProps={groupedInputProps}
      groupedInputClassName={groupedInputClassName}
      readOnlyFormatter={readOnlyFormatter}
      onChange={handleChange}
      {...editableFieldWithPatchRequestProps}
    />
  );
}
