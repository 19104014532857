import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function convertToObject(data) {
  return typeof data !== 'string' ? data : JSON.parse(data);
}
function GetAdditionalFreeformContent({ project }) {
  const dataObject = convertToObject(project.extra_fields_json);
  const additional_freeform_content = dataObject['additional_freeform_content'];

  if (additional_freeform_content !== undefined) {
    return (
      <div>
        <span>{additional_freeform_content.title}</span>
        <div dangerouslySetInnerHTML={{ __html: additional_freeform_content.htmlcode }} />
      </div>
    );
  } else {
    return <></>;
  }
}
function GetAdditionalAttributes({ project }) {
  const dataObject = convertToObject(project.extra_fields_json);
  const additional_attributes = dataObject['additional_attributes'];

  if (additional_attributes !== undefined) {
    return Object.entries(additional_attributes).map(([key, val]) => (
      <li key={key}>
        <strong>{key}: </strong>
        {val}
      </li>
    ));
  } else {
    return <></>;
  }
}
export default function ExtraFieldsList({ project }) {
  return (
    <>
      <h2 className="fs-0 mt-4">Additional Information</h2>
      <Container fluid>
        <Row>
          <Col>
            <GetAdditionalFreeformContent project={project} />
          </Col>
          <Col>
            <ul>
              <GetAdditionalAttributes project={project} />
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}
