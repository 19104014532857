import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, CardBody, CardImg } from 'reactstrap';

import { CircleStackIcon } from 'atoms/misc';
import { KanbanContext } from 'context/kanban';
import { parse } from 'date-fns';

const TaskCard = ({ taskCardItemId, taskCard, taskCardImage, taskCardIndex }) => {
  const { getItemStyle, handleCalendarEventClick } = useContext(KanbanContext);

  return (
    <Draggable draggableId={`${taskCard.id}`} index={taskCardIndex}>
      {(provided, snapshot) => (
        <div
          className="kanban-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
        >
          <Card
            className="kanban-item-card hover-actions-trigger"
            style={getItemStyle(snapshot.isDragging)}
            onClick={() => {
              handleCalendarEventClick({
                event: {
                  id: taskCard.id,
                  start: parse(taskCard.start, 'yyyy-MM-dd', new Date()),
                  end: parse(taskCard.end, 'yyyy-MM-dd', new Date()),
                  multiday: false,
                  extendedProps: {
                    ...taskCard,
                    start: parse(taskCard.start, 'yyyy-MM-dd', new Date()),
                    end: parse(taskCard.end, 'yyyy-MM-dd', new Date()),
                    multiday: false
                  }
                }
              });
            }}
          >
            {taskCardImage && (
              <CardImg
                top
                width="100%"
                src={taskCardImage.url}
                className="overflow-hidden position-relative"
                alt="Card image cap"
              />
            )}
            <CardBody>
              {taskCard.is_completed ? <CircleStackIcon iconName="check" /> : null}
              <div className="text-wrap cursor-pointer">
                <strong>{taskCard.project.name}</strong>
                <p className="mb-0">
                  Act / Est :{' '}
                  {parseFloat(taskCard?.calendar_metric_value) ? parseFloat(taskCard.calendar_metric_value) : '0'} /{' '}
                  {parseFloat(taskCard?.calendar_metric_estimate) ? parseFloat(taskCard.calendar_metric_estimate) : '0'}
                </p>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
