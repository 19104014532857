import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

const ContentWithAsideLayout = ({ banner, aside, footer, isStickyAside, children, ratio, fullHeight = false }) => {
  return (
    <Fragment>
      {banner}
      <Row noGutters className={`${fullHeight && 'h-100'}`}>
        <Col
          lg={Math.round((12 / (ratio[0] + ratio[1])) * ratio[0])}
          className={classNames('pr-lg-2', { 'mb-3': !isStickyAside })}
        >
          {children}
        </Col>
        <Col
          lg={Math.round((12 / (ratio[0] + ratio[1])) * ratio[1])}
          className={classNames('pl-lg-2', { 'mb-3': !isStickyAside })}
        >
          {isStickyAside ? <div className="sticky-top sticky-sidebar">{aside}</div> : aside}
        </Col>
      </Row>
      {footer}
    </Fragment>
  );
};

ContentWithAsideLayout.propTypes = {
  aside: PropTypes.element.isRequired,
  banner: PropTypes.element,
  footer: PropTypes.element,
  isStickyAside: PropTypes.bool,
  children: PropTypes.node,
  ratio: PropTypes.array
};

ContentWithAsideLayout.defaultProps = { isStickyAside: true, ratio: [8, 4] };

export default ContentWithAsideLayout;
