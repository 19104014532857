import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { subDays, addDays } from 'date-fns';

import { CurrencyInput, DateInput, CheckboxWithLabel } from 'atoms/form';

/**
 *
 * @param {state}: state that contains keys for all elements of fields
 * @param {inputProps}: will be spread into EVERY input
 */
export default function CalendarItemFormFields({ calendarData, state, setState }) {
  // Values are stored in db and read by fullcalendar exclusive, but we show to user inclusive.
  const userViewEndDate = state.end && subDays(state.end, 1);

  function setEndDate(userInputDate) {
    setState({
      ...state,
      end: userInputDate && addDays(userInputDate, 1)
    });
  }

  function setStartDate(userInputDate) {
    if (userInputDate && userInputDate >= userViewEndDate) {
      setState({
        ...state,
        start: userInputDate,
        end: null
      });
    } else {
      setState({
        ...state,
        start: userInputDate
      });
    }
  }

  function currencyChangeHandler(fieldName) {
    return function handleDateChange(event) {
      setState({
        ...state,
        [fieldName]: Number(event.target.value)
      });
    };
  }

  const [isMultiDay, setIsMultiDay] = React.useState(Boolean(state.end) && state.end !== state.start);

  return (
    <>
      <FormGroup>
        <Label for="start">Start Date (Required)</Label>
        <DateInput id="start" required value={state.start} onChange={setStartDate} />
      </FormGroup>
      <CheckboxWithLabel
        label="Multi-day event"
        id="multi-day-event"
        checked={isMultiDay}
        onChange={e => {
          if (!e.target.checked) {
            setEndDate(null);
          } else {
            setEndDate(state.start && addDays(state.start, 1));
          }
          setIsMultiDay(e.target.checked);
        }}
      />
      {isMultiDay && (
        <FormGroup>
          <Label for="end">End Date (Required if Multi-day event)</Label>
          <DateInput
            required
            minDate={addDays(state.start, 1)}
            id="end"
            value={userViewEndDate}
            onChange={setEndDate}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label for="calendar_metric_value">{calendarData.metric_label} (Required)</Label>
        <CurrencyInput
          required
          id="calendar_metric_value"
          value={state.calendar_metric_value}
          onChange={currencyChangeHandler('calendar_metric_value')}
        />
      </FormGroup>
      <FormGroup>
        <Label for="note">Notes</Label>
        <Input
          type="textarea"
          id="note"
          value={state.note}
          onChange={e => {
            setState({
              ...state,
              note: e.target.value
            });
          }}
        />
      </FormGroup>
    </>
  );
}
